import React, { useContext, useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import './styles.scss';
import lockIcon from '../../../assets/image/svg/lock.svg';
import GlobalContext from '../../../context/GlobalContext';
import BigRoundButton from '../../BigRoundButton';
import { updateQueryParam } from '../../../utils/helperFn';

interface Props { }

const CurrentContractForm = (props: Props) => {
  const gContext: any = useContext(GlobalContext);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
    updateQueryParam('step', '2')

    if (gContext.goGetRegistrationData().currentProvider === 'I_HAVE_NO_ACTIVE_SERVICE') {
      gContext.goSetRegistrationData({
        ...gContext.goGetRegistrationData(),
        registerStep: 1
      });
    }
  }, []);

  const setContract = (contractType: 'IN_CONTRACT' | 'OUT_OF_CONTRACT' | 'DONT_KNOW') => {
    if (gContext.goGetRegistrationData()) {
      gContext.goSetRegistrationData({
        ...gContext.goGetRegistrationData(),
        currentContractType: contractType,
        registerStep: 3
      });
    }
  };

  const handleContinue = () => {
    gContext.goSetRegistrationData({
      ...gContext.goGetRegistrationData(),
      registerStep: 3
    });
  }

  return (
    <>
      <Row className="justify-content-center pt-1 pb-4 ml-5 mr-5">
        <Col sm={12} md={4}>
          <Row>
            {/* IN CONTRACT */}
            <Col
              sm={12}
              key={'in contract'}
              onClick={() => {
                setContract('IN_CONTRACT');
              }}
              className={`providerButton text-center mb-3 ${gContext.goGetRegistrationData().currentContractType === 'IN_CONTRACT' ? 'selected' : ''}`}
            >
              <span>Yes, still in contract</span>
            </Col>

            {/* OUT OF CONTRACT */}
            <Col
              sm={12}
              key={'out of contract'}
              onClick={() => {
                setContract('OUT_OF_CONTRACT');
              }}
              className={`providerButton text-center mb-3 ${gContext.goGetRegistrationData().currentContractType === 'OUT_OF_CONTRACT' ? 'selected' : ''}`}
            >
              <span>No, out of contract</span>
            </Col>

            {/* DON'T KNOW */}
            <Col
              sm={12}
              key={'dont know'}
              onClick={() => {
                setContract('DONT_KNOW');
              }}
              className={`providerButton text-center mb-3 ${gContext.goGetRegistrationData().currentContractType === 'DONT_KNOW' ? 'selected' : ''}`}
            >
              <span>Don't know</span>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col sm={12} md={4}>
          <BigRoundButton
            title="Continue"
            onClick={() => handleContinue()}
          />
        </Col>
      </Row>

      {/* SSL Lock Icon */}
      <Row className="justify-content-center">
        <Col sm={12} md={4} className="text-center mt-3">
          <img
            src={lockIcon}
            alt=""
            className="img-fluid"
            style={{ maxWidth: '14px', color: 'black' }}
          />{' '}
          <span style={{ color: 'black', fontSize: '0.8em', marginLeft: 3 }}>SSL Secure</span>
        </Col>
        {/* Go Back */}
        <Col sm={12} className="text-center mt-5">
          <p
            style={{ cursor: 'pointer' }}
            onClick={() =>
              gContext.goSetRegistrationData({
                ...gContext.goGetRegistrationData(),
                registerStep: 1
              })
            }
          >
            ← Go Back
          </p>
        </Col>
      </Row>
    </>
  );
};

export default CurrentContractForm;
